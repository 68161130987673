@import 'mixins';


@include font-face('Lexend', '../fonts/Lexend-Black', 900, normal); 
@include font-face('Lexend', '../fonts/Lexend-Bold', 700, normal); 
@include font-face('Lexend', '../fonts/Lexend-ExtraBold', 800, normal); 
@include font-face('Lexend', '../fonts/Lexend-SemiBold', 600, normal); 
@include font-face('Lexend', '../fonts/Lexend-Medium', 500, normal); 
@include font-face('Lexend', '../fonts/Lexend-Regular', 400, normal); 
@include font-face('Lexend', '../fonts/Lexend-Light', 300, normal); 
@include font-face('Lexend', '../fonts/Lexend-ExtraLight', 200, normal); 
@include font-face('Lexend', '../fonts/Lexend-Thin', 100, normal); 

*{
    font-family: 'Lexend';
    font-weight: 400;
}