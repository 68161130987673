// Общие настройки


.container {
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.site-container {
    height: 100%;
}

.site-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.page-container {
    flex: 1 0 auto;
}

.footer {
    flex: 0 0 auto;
}